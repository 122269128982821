import React, { useEffect, useContext } from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import { AnalyticsLocationProvider } from 'latitude-analytics';
import { Heading4 } from '@latitude/heading';
import { Box } from '@/components/Box/Box';
import { Card } from '@/components/Card/Card';
import ImportantInformation from '@/components/ImportantInformation/ImportantInformation';
import Layout from '@/components/layout';
import Metadata from '@/components/Metadata/Metadata';
import MobileAppInstallPrompts from '@latitude/mobile-app-install-prompt/MobileAppInstallPrompt';
import PageData from '@/data/pages/calculators-and-tools/budget-planner.json';
import StickyNavigationBranded from '@/components/StickyNavigation/StickyNavigationBranded';
import { ConfettiFramedContainer } from '@latitude/confetti-framed-container';
import { CardGroup, CardGroupItem } from '@/components/CardGroup/CardGroup';
import HorizontalRule from '@/components/HorizontalRule/HorizontalRule';
import { ALIGN, BREAKPOINT, COLOR, MARGIN, PADDING } from '@/utils/constants';
import { Text } from '../components/Text';
import BudgetPlannerTool from '../components/BudgetPlannerTool/BudgetPlannerTool';
import ArticleTile from '../templates/life-done-better/ArticleTile';
import heroImage from '../images/hero/budget-planner.webp';
import { FeatureTiles } from '@latitude/feature-tiles';

import { PageContext } from '@/context/PageContext';
import { CONTENTFUL_COMPONENTS } from '@/utils/constants';
import HeroBanner from '@/components/lab-components/HeroBanner';
import { useSetState } from '@/utils/hooks';
import PageHeaderSection from '@/components/PageLayout/PageHeader';

const BudgetPlannerPage = ({ data, location }) => {
  /** Contentful Data */
  const contentfulPageData = useContext(PageContext);
  const state = contentfulPageData?.updatedBody || {};
  /** - END - */

  return (
    <Layout location={location}>
      <main className="navigation-spacer">
        <Box backgroundColor={COLOR.GREY6}>
          <MobileAppInstallPrompts />
          <Metadata
            title={PageData.title}
            description={PageData.description}
            canonical={location.pathname}
            addTitleSuffix=""
          />
          {state?.heroBannerData?.[0] ? (
            <HeroBanner {...state?.heroBannerData[0]} />
          ) : (
            <PageHeaderSection
              pageImage={heroImage}
              title="Budget Planner"
              subTitle="Get a better view of your finances with our easy to use budget planner."
            />
          )}
          <div
            className="d-none d-lg-block"
            css={`
              position: relative;
              z-index: 5;
            `}
          >
            <StickyNavigationBranded
              items={PageData.nav}
              offsetElem="[data-sticky-navigation-offset]"
            />
          </div>

          <ConfettiFramedContainer id="planner" hasPlainBgColor="transparent">
            <AnalyticsLocationProvider location="Budget Planner">
              <Box
                css={`
                  padding: ${PADDING.P8};
                  @media (min-width: ${BREAKPOINT.MD}) {
                    padding: ${PADDING.P56};
                  }
                `}
              >
                <Heading4
                  color={COLOR.BLACK}
                  align={ALIGN.CENTER}
                  css={`
                    margin-bottom: ${PADDING.P16};
                    margin-top: ${PADDING.P24};
                    @media (min-width: ${BREAKPOINT.MD}) {
                      margin-top: 0;
                    }
                  `}
                >
                  Budget Planner
                </Heading4>
                <Text
                  align={ALIGN.CENTER}
                  css={`
                    padding: 0 ${PADDING.P16};
                    max-width: 540px;
                    margin: 0 auto ${PADDING.P32};
                  `}
                >
                  Use our handy planner to work out where you’re spending, so
                  you can save for what you really want!{' '}
                </Text>

                <BudgetPlannerTool id="repayment-calculator" />
              </Box>
            </AnalyticsLocationProvider>
          </ConfettiFramedContainer>

          <FeatureTiles
            id="calculators-and-tools"
            tiles={PageData.content.calculators}
          />

          <HorizontalRule />

          <Box.Section
            id={PageData.nav[1].anchor}
            backgroundColor={COLOR.TRANSPARENT}
            paddingTop={MARGIN.M32}
            paddingBottom={MARGIN.M32}
          >
            <Heading4 align={ALIGN.CENTER} color={COLOR.BLACK}>
              Recommended reads
            </Heading4>
            <CardGroup>
              {data.allMarkdownRemark.edges.map(item => (
                <CardGroupItem key={item.node.frontmatter.path}>
                  <ArticleTile
                    aria-label={item.node.frontmatter.title}
                    role="img"
                    isBranded
                    {...item.node.frontmatter}
                  />
                </CardGroupItem>
              ))}
            </CardGroup>
          </Box.Section>

          <ImportantInformation
            data={require('../data/json/disclaimer/personal-loan1.json')}
            sectionOneColummClasses="col-10 offset-1 col-lg-5 offset-lg-1"
            sectionTwoColummClasses="col-10 offset-1 col-lg-5 offset-lg-0"
            additionalPaymentsDisclaimerOne
            additionalPaymentsDisclaimerTwo
          />
        </Box>
      </main>
    </Layout>
  );
};

export const pageQuery = graphql`
  {
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: {
          glob: "**/src/templates/life-done-better/articles/*.md"
        }
        frontmatter: {
          title: {
            in: [
              "How to Budget Your Finances for the Future"
              "Home Budgeting: All the Ins and Outs"
              "Tips to Help You Become a Super Saver"
            ]
          }
        }
      }
    ) {
      edges {
        node {
          html
          frontmatter {
            title
            path
            thumbnail
            description
          }
        }
      }
    }
  }
`;

export default BudgetPlannerPage;

const HowToApplyCard = styled(Card)`
  flex: 1 1 auto; // fixes IE11 align issue
  max-width: 280px;
  > div:first-child {
    width: 128px;
    height: 128px;
    margin: 0 auto;
    display: flex;
    align-items: center;
  }
  svg {
    stroke: inherit;
    width: auto;
    height: auto;
  }
  h5 {
    color: ${COLOR.BLACK};
  }
`;
